import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Link from 'components/Link'
import { Flex, Box, Text, Heading } from 'rebass/styled-components'
import Img from 'gatsby-image'
import priceHelper from 'utils/priceHelper'

export const pageQuery = graphql`
  query GetAllProducts {
    allContentfulProduct(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          name
          currency
          amount
          sku
          image {
            fluid(maxWidth: 350, maxHeight: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const ProductsPage = ({ data }) => {
  const products = data.allContentfulProduct.edges

  return (
    <Layout>
      <SEO title="Products" />
      <Heading textAlign="center" fontSize={[4, 4, 5]} py={3}>
        Lastest Products
      </Heading>
      <Flex flexWrap="wrap" mx={-2}>
        {products.map(({ node }, idx) => {
          return (
            <Box width={1 / 3} px={2} key={idx}>
              <ProductLink to={'/products/' + node.sku}>
                <Box width={1}>
                  <Img fluid={node.image.fluid} />
                </Box>
                <Text as="p" textAlign="center" py={2}>{`${
                  node.name
                } ${priceHelper(node.amount)}`}</Text>
              </ProductLink>
            </Box>
          )
        })}
      </Flex>
    </Layout>
  )
}

export default ProductsPage

const ProductLink = styled(Link)`
  flex-direction: column;
`
